import React from 'react'

const Check = () => {
    return (
        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.60742 10.3945L6.93945 15.7266L20.3926 2.27344" stroke="white" strokeWidth="4" />
        </svg>
    )
}

export default Check
