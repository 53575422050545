const Camelot = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0355 28V18.684C11.8098 18.922 10.2995 19.942 10.2995 19.942L8.88005 18.344C11.2079 16.6893 14.66 16.6213 14.66 16.6213L15.2278 10.3653C14.8531 10.116 14.6032 9.69667 14.6032 9.20933C14.6032 8.45 15.2278 7.82667 15.9886 7.82667C16.7494 7.82667 17.374 8.45 17.374 9.20933C17.374 9.69667 17.1242 10.1273 16.7494 10.3653L17.3172 16.6213C17.3172 16.6213 20.7693 16.678 23.0972 18.344L21.6778 19.942C21.6778 19.942 20.1675 18.922 17.9418 18.684V28C26.6401 21.5627 27.3555 12.2127 27.3328 9.10733C27.3328 8.46133 26.9467 7.872 26.3562 7.61133C24.6188 6.84067 20.6558 5.33333 16 5.33333C11.3442 5.33333 7.38112 6.84067 5.64372 7.61133C5.05324 7.872 4.66715 8.46133 4.66715 9.10733C4.64443 12.2127 5.35983 21.5627 14.0355 28Z"
                className="fill-[#FFAF1D] group-hover:fill-[#181716]"
            />
        </svg>
    )
}

export default Camelot
